import { baseStyles, normalize } from '@123-front/ui-kit';
import '@brainhubeu/react-carousel/lib/style.css';
import { Global } from '@emotion/core';
import React, { Fragment } from 'react';
import PartnerDetails from '../../../components/partners/br/serasa-prudential';
import SEO from '../../../components/seo';

const SerasaLandingBR: React.FC = () => {
  return (
    <Fragment>
      <Global styles={[normalize, baseStyles]}></Global>
      <SEO
        title="Segurança, tranquilidade e proteção para você em todos os momentos!"
        description="Seguro Acidentes Pessoais. Uma parceria entre Serasa e Prudential que busca proteger nosso maior bem, que é você."
        image={'https://branding.123seguro.com/favicons/prudential.png'}
      />
      <PartnerDetails />
    </Fragment>
  );
};

export default SerasaLandingBR;
